.login-container {
  height: 100vh;
  width: 100%;
  z-index: 1000;
  position: fixed;
  inset: 0;
}

.login-container > .login-content {
  width: 300px;
  margin: 5rem auto auto;
}

/*# sourceMappingURL=index.f7516843.css.map */
